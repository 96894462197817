import React, { useContext } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../ItemTypes.js";
import CustomGroupList from "./CustomGroupList.jsx";
import { DropToggle, ItemMoved, DarkMode } from "../context";
import { Box, Card, Typography } from "@mui/material";
import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";

export const CustomGroupItem = ({ dropitemtracking }) => {
  const { dropToggle, setDropToggle } = useContext(DropToggle);
  const { itemMoved } = useContext(ItemMoved);
  const { darkMode } = useContext(DarkMode);

  function TriggerDrop() {
    const index = dropToggle.indexOf(itemMoved);
    // item is already dropped, do nothing
    if (index < 0) {
      var current_drop_data = [];
      current_drop_data.push(itemMoved);
      setDropToggle(current_drop_data.concat(dropToggle));
    }
  }
  // eslint-disable-next-line
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: ItemTypes.LIST,
      canDrop: () => true,
      drop: () => null,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [dropitemtracking]
  );

  return (
    <Box ref={drop} onDrop={TriggerDrop}>
      <Card
        className={
          darkMode
            ? dropToggle?.length
              ? "customgroupdropcarddarkmini"
              : "customgroupdropcarddark"
            : dropToggle?.length
            ? "customgroupdropcardmini"
            : "customgroupdropcard"
        }
        ref={drop}
        onDrop={TriggerDrop}
      >
        <AddCircleOutlineTwoToneIcon
          className={
            darkMode
              ? dropToggle?.length
                ? "icondropdarkmini"
                : "icondropdark"
              : dropToggle?.length
              ? "icondropmini"
              : "icondrop"
          }
        />{" "}
      </Card>
      <Typography
        variant="h8"
        component="div"
        sx={{
          textAlign: "center",
          fontSize: dropToggle?.length ? "0.75em" : "1em",
        }}
      >
        {"Drag/Drop here"}
      </Typography>

      {dropToggle.length ? (
        <Box className="dropbox">
          <CustomGroupList dropToggle={dropToggle} />
        </Box>
      ) : null}
    </Box>
  );
};
