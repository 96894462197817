import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  List,
  Grid2,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Tooltip,
} from "@mui/material";
import FallStreakAPI from "../api/FallStreakApi";
import {
  TokenData,
  DarkMode,
  TenantGroupData,
  CustomGroupData,
  AllRolesPermissions,
  NewAlert,
} from "../context";
import { EntraGroupItem } from "./EntraGroupItem";
import CustomGroups from "./CustomGroups";
import CustomSearch from "./CustomSearch";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";

export default function Access({ dropitemtracking }) {
  const { tenantGroupData, setTenantGroupData } = useContext(TenantGroupData);
  const { setAllRolesPermissions } = useContext(AllRolesPermissions);
  const { setNewAlert } = useContext(NewAlert);
  const [refresh, setRefresh] = useState(false);
  const { setCustomGroupData } = useContext(CustomGroupData);
  const { tokenData } = useContext(TokenData);
  const { darkMode } = useContext(DarkMode);
  const [entraSearchTerm, setEntraSearchTerm] = useState("");
  const initialTenantRequest = useRef(false);
  const initialCustomRequest = useRef(false);
  const initialRolesPermissions = useRef(false);

  const TenantGroupInfo = useCallback(() => {
    initialTenantRequest.current = true;
    FallStreakAPI({ token_data: tokenData }, "POST", "tenantgroupsinfo", "")
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data) {
            setTenantGroupData(response?.data);
          }
        } else if (response?.status === 401) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        } else if (response?.status === 403) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [setTenantGroupData, tokenData, setNewAlert]);

  const CustomGroupInfo = useCallback(
    (refreshtoggle) => {
      initialCustomRequest.current = true;
      FallStreakAPI({ token_data: tokenData }, "POST", "customgroupsinfo", "")
        .then(function (response) {
          if (response?.status === 200) {
            if (response?.data) {
              setCustomGroupData(response?.data);
            }
          } else if (response?.status === 401) {
            setNewAlert({
              uid: "customgroupinfo",
              color: "error",
              message: response?.response?.data?.detail,
            });
          } else if (response?.status === 403) {
            setNewAlert({
              uid: "customgroupinfo",
              color: "error",
              message: response?.response?.data?.detail,
            });
          }
          setRefresh(false);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    [setCustomGroupData, tokenData, setNewAlert]
  );

  const RolesPermissions = useCallback(() => {
    initialRolesPermissions.current = true;
    FallStreakAPI({ token_data: tokenData }, "POST", "rolespermissions", "")
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data) {
            setAllRolesPermissions(response?.data);
          }
        } else if (response?.status === 401) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        } else if (response?.status === 403) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
        setNewAlert([{ color: "error", message: err }]);
      });
  }, [setNewAlert, tokenData, setAllRolesPermissions]);

  useEffect(() => {
    if (!initialTenantRequest.current) {
      TenantGroupInfo();
    }
    if (!initialCustomRequest.current || refresh) {
      CustomGroupInfo(refresh);
    }
    if (!initialRolesPermissions.current) {
      RolesPermissions();
    }
  }, [CustomGroupInfo, TenantGroupInfo, RolesPermissions, refresh]);

  function BuildEntraGroupList() {
    const list_data = tenantGroupData.map((x, index) => {
      if (
        x?.name.toLowerCase().includes(entraSearchTerm.toLowerCase()) ||
        entraSearchTerm === ""
      ) {
        return (
          <EntraGroupItem
            key={`${index} + entragroupitem`}
            data={x?.name}
            index={index}
            customgroup={false}
          />
        );
      } else {
        return null;
      }
    });
    return list_data;
  }

  return (
    <Box>
      <Grid2 container>
        <Grid2 size={5}>
          <Card className={darkMode ? "appcardsdark" : "appcards"}>
            <CardContent>
              <Grid2 container>
                <Grid2 size={4}>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    {"Your MS Groups"}
                  </Typography>
                </Grid2>
                <Grid2 size={2}>
                  <Tooltip title="All the groups found in your Azure AD group list">
                    <HelpTwoToneIcon
                      className={
                        darkMode ? "helpiconconfigdark" : "helpiconconfig"
                      }
                      sx={{ pt: "5px" }}
                    />
                  </Tooltip>
                </Grid2>
              </Grid2>
              <Grid2 container>
                <Grid2 size={12}>
                  <CustomSearch
                    setSearchTerm={setEntraSearchTerm}
                    searchTerm={entraSearchTerm}
                    placeholder={"Search for Group..."}
                  />
                </Grid2>
              </Grid2>

              <Grid2 size={12}>
                <List
                  key={"fulllist"}
                  dense={true}
                  sx={{
                    width: "100%",
                    maxWidth: "100%",
                    bgcolor: "transparent",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: "55vh",
                    "& ul": { padding: 0 },
                  }}
                >
                  {tenantGroupData ? (
                    BuildEntraGroupList()
                  ) : (
                    <CircularProgress color="success" />
                  )}
                </List>
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 size={1}></Grid2>
        <Grid2 size={6}>
          <Card className={darkMode ? "appcardsdark" : "appcards"}>
            {tenantGroupData && (
              <CustomGroups
                dropitemtracking={dropitemtracking}
                setRefresh={setRefresh}
                refresh={refresh}
              />
            )}
          </Card>
        </Grid2>
      </Grid2>
    </Box>
  );
}
