import React, { useContext } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid2,
  OutlinedInput,
  Select,
  MenuItem,
  Tooltip,
  Dialog,
  IconButton,
  List,
  ListItem,
  Box,
} from "@mui/material";
import { DarkMode } from "../context";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";

export default function AddGroupModal({
  addOpen,
  handleClose,
  setGroupName,
  groupName,
  selectedRoleOption,
  selectRoles,
  handleRoleSelect,
  crudGroup,
  summaryList,
}) {
  const { darkMode } = useContext(DarkMode);

  return (
    <Dialog open={addOpen} onClose={handleClose}>
      <Card className={darkMode ? "appcardsdark" : "appcards"}>
        <CardHeader
          title="Create a New Custom Group"
          className={darkMode ? "appcardstitledark" : "appcardstitle"}
        />
        <CardContent>
          <Grid2 container rowGap={2}>
            Group Name
            <Grid2 size={12}>
              <OutlinedInput
                placeholder="Enter the group Name"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                sx={{
                  color: darkMode ? "white " : "#001864",
                  width: "100%",
                }}
              />
            </Grid2>
            Select Role
            <Grid2 size={12}>
              <Select
                MenuProps={{ autoFocus: false }}
                id="role-select"
                value={
                  selectedRoleOption
                    ? selectedRoleOption
                    : selectRoles[0]
                    ? selectRoles[0]
                    : "Select a Role"
                }
                onChange={(e) => handleRoleSelect(e.target.value)}
                sx={{
                  backgroundColor: darkMode ? "#242d3e" : "#ffffff",
                  color: darkMode ? "white" : "#001864",
                  Width: "100%",
                }}
              >
                {selectRoles.map((option, i) => (
                  <MenuItem
                    key={i}
                    value={option}
                    className={
                      darkMode
                        ? "customsearchlistitemdark ifhovered"
                        : "customsearchlistitem ifhovered"
                    }
                  >
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </Grid2>
            <Grid2 size={12}>
              {summaryList ? (
                <List>
                  {summaryList.map((option, i) => (
                    <ListItem
                      key={i}
                      className={
                        darkMode
                          ? "customsearchlistitemdark"
                          : "customsearchlistitem"
                      }
                    >
                      {`- ${option}`}
                    </ListItem>
                  ))}
                </List>
              ) : null}
            </Grid2>
          </Grid2>
        </CardContent>
        <CardActions>
          {groupName !== "" ? (
            <Box sx={{ width: "100%", textAlign: "center" }}>
              <Tooltip title="Save Group" sx={{ borderStyle: "solid" }}>
                <IconButton onClick={() => crudGroup("create")}>
                  <SaveTwoToneIcon
                    className={
                      darkMode ? "saveiconconfigdark" : "saveiconconfig"
                    }
                  />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
        </CardActions>
      </Card>
    </Dialog>
  );
}
