import axios from "axios";

export default function FallStreakAPI(query, method, url, parameters) {
  return new Promise((resolve) => {
    const options = {
      method: method,
      url: window.location.origin + "/api/" + url,
      data: query,
      params: parameters,
    };

    axios
      .request(options)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (err) {
        console.log(err);
        resolve(err);
      });
  });
}
