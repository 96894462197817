import React, { useMemo, useState, useEffect } from "react";
import { PageLayout } from "./components/PageLayout";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ConfigPage from "./pages/ConfigPage";
import NotFound from "./pages/NotFound";
import Footer from "./components/Footer";
import SnackAlert from "./components/SnackAlert";
import { Box } from "@mui/material";
import { loginRequest } from "./authConfig";
import {
  ClientName,
  BucketSearch,
  UserPermissions,
  PageLocation,
  DarkMode,
  TokenData,
  DropToggle,
  ItemMoved,
  CustomGroupData,
  TenantGroupData,
  SelectedGroupOption,
  AlertResponses,
  AllRolesPermissions,
  BucketTableData,
  NewAlert,
} from "./context";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./css/app.css";
import "./css/dashboard.css";

export default function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [clientNameString, setClientNameString] = useState("");
  const [newAlert, setNewAlert] = useState({});
  const [allRolesPermissions, setAllRolesPermissions] = useState([]);
  const [alertResponses, setAlertResponses] = useState([]);
  const [customGroupData, setCustomGroupData] = useState(null);
  const [tenantGroupData, setTenantGroupData] = useState(null);
  const [bucketTableData, setBucketTableData] = useState(null);
  const [selectedGroupOption, setSelectedGroupOption] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [tokenData, setTokenData] = useState(null);
  const [dropToggle, setDropToggle] = useState([]);
  const [itemMoved, setItemMoved] = useState([]);
  const [bucketSearchTerm, setBucketSearchTerm] = useState("");
  const [userPermissions, setUserPermissions] = useState({});
  const [pageLocation, setPageLocation] = useState("dashboard");

  const selectedGroupOptionDetails = useMemo(
    () => ({ selectedGroupOption, setSelectedGroupOption }),
    [selectedGroupOption]
  );

  const newAlertDetails = useMemo(
    () => ({ newAlert, setNewAlert }),
    [newAlert]
  );

  const bucketTableDataDetails = useMemo(
    () => ({ bucketTableData, setBucketTableData }),
    [bucketTableData]
  );

  const allRolesPermissionsDetails = useMemo(
    () => ({ allRolesPermissions, setAllRolesPermissions }),
    [allRolesPermissions]
  );

  const alertResponsesDetails = useMemo(
    () => ({ alertResponses, setAlertResponses }),
    [alertResponses]
  );

  const customGroupDataDetails = useMemo(
    () => ({ customGroupData, setCustomGroupData }),
    [customGroupData]
  );

  const tenantGroupDataDetails = useMemo(
    () => ({ tenantGroupData, setTenantGroupData }),
    [tenantGroupData]
  );

  const dropToggleDetails = useMemo(
    () => ({ dropToggle, setDropToggle }),
    [dropToggle]
  );

  const itemMovedDetails = useMemo(
    () => ({ itemMoved, setItemMoved }),
    [itemMoved]
  );

  const pageLocationDetails = useMemo(
    () => ({ pageLocation, setPageLocation }),
    [pageLocation]
  );

  const tokenDataDetails = useMemo(
    () => ({ tokenData, setTokenData }),
    [tokenData]
  );

  const darkModeToggle = useMemo(() => ({ darkMode, setDarkMode }), [darkMode]);

  const clientName = useMemo(
    () => ({ clientNameString, setClientNameString }),
    [clientNameString]
  );

  const userPermissionsDetails = useMemo(
    () => ({ userPermissions, setUserPermissions }),
    [userPermissions]
  );

  const bucketSearch = useMemo(
    () => ({ bucketSearchTerm, setBucketSearchTerm }),
    [bucketSearchTerm]
  );

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  useEffect(() => {
    if (isAuthenticated) {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((tokenresponse) => {
          setTokenData(tokenresponse);
        })
        .catch(function (err) {
          console.log(err);
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
            idTokenHint: accounts[0]?.idToken,
          });
        });
    }
  }, [accounts, instance, isAuthenticated, setTokenData]);

  useEffect(() => {
    if (localStorage.getItem("darkmode")) {
      setDarkMode(localStorage.getItem("darkmode") === "false" ? false : true);
    }
  }, [setDarkMode]);

  return isAuthenticated ? (
    <ClientName.Provider value={clientName}>
      <AlertResponses.Provider value={alertResponsesDetails}>
        <UserPermissions.Provider value={userPermissionsDetails}>
          <PageLocation.Provider value={pageLocationDetails}>
            <DarkMode.Provider value={darkModeToggle}>
              <TokenData.Provider value={tokenDataDetails}>
                <AllRolesPermissions.Provider
                  value={allRolesPermissionsDetails}
                >
                  <NewAlert.Provider value={newAlertDetails}>
                    <PageLayout>
                      <Box>
                        <AuthenticatedTemplate>
                          <SnackAlert />
                          {pageLocation === "dashboard" ? (
                            <BucketSearch.Provider value={bucketSearch}>
                              <BucketTableData.Provider
                                value={bucketTableDataDetails}
                              >
                                <Dashboard />
                              </BucketTableData.Provider>
                            </BucketSearch.Provider>
                          ) : pageLocation === "configuration" ? (
                            <TenantGroupData.Provider
                              value={tenantGroupDataDetails}
                            >
                              <CustomGroupData.Provider
                                value={customGroupDataDetails}
                              >
                                <SelectedGroupOption.Provider
                                  value={selectedGroupOptionDetails}
                                >
                                  <DndProvider backend={HTML5Backend}>
                                    <DropToggle.Provider
                                      value={dropToggleDetails}
                                    >
                                      <ItemMoved.Provider
                                        value={itemMovedDetails}
                                      >
                                        <ConfigPage />
                                      </ItemMoved.Provider>
                                    </DropToggle.Provider>
                                  </DndProvider>
                                </SelectedGroupOption.Provider>
                              </CustomGroupData.Provider>
                            </TenantGroupData.Provider>
                          ) : (
                            <NotFound />
                          )}
                        </AuthenticatedTemplate>
                      </Box>
                    </PageLayout>
                    <Footer />
                  </NewAlert.Provider>
                </AllRolesPermissions.Provider>
              </TokenData.Provider>
            </DarkMode.Provider>
          </PageLocation.Provider>
        </UserPermissions.Provider>
      </AlertResponses.Provider>
    </ClientName.Provider>
  ) : (
    <Box>
      <UnauthenticatedTemplate>
        <Login darkMode={darkMode} />
      </UnauthenticatedTemplate>
    </Box>
  );
}
