import React, { useContext } from "react";
import { useMsal } from "@azure/msal-react";
import { Button, IconButton, Typography, Tooltip } from "@mui/material";
import SettingsTwoToneIcon from "@mui/icons-material/SettingsTwoTone";
import DarkModeTwoToneIcon from "@mui/icons-material/DarkModeTwoTone";
import LightModeTwoToneIcon from "@mui/icons-material/LightModeTwoTone";
import { UserPermissions, PageLocation, DarkMode } from "../context";

/**
 * sign out button for page layout app bar
 */

export const SignOutButton = () => {
  const { instance, accounts } = useMsal();
  const { userPermissions } = useContext(UserPermissions);
  const { setPageLocation } = useContext(PageLocation);
  const { darkMode, setDarkMode } = useContext(DarkMode);

  function ClickSettings() {
    setPageLocation("configuration");
  }

  function ClickDarkMode() {
    localStorage.setItem("darkmode", darkMode ? false : true);
    setDarkMode(darkMode ? false : true);
  }

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
      idTokenHint: accounts[0]?.idToken,
    });
  };

  return (
    <>
      <Tooltip
        title={darkMode ? "Switch to light mode" : "Switch to dark mode"}
      >
        <IconButton onClick={() => ClickDarkMode()}>
          {darkMode ? (
            <DarkModeTwoToneIcon
              className={darkMode ? "appbarbuttonsdark" : "appbarbuttons"}
              sx={{ paddingRight: 2 }}
            />
          ) : (
            <LightModeTwoToneIcon
              className={darkMode ? "appbarbuttonsdark" : "appbarbuttons"}
              sx={{ paddingRight: 2 }}
            />
          )}
        </IconButton>
      </Tooltip>
      {userPermissions.config && (
        <Tooltip title={"System Configuration"}>
          <IconButton onClick={() => ClickSettings()}>
            <SettingsTwoToneIcon
              className={darkMode ? "appbarbuttonsdark" : "appbarbuttons"}
              sx={{ paddingRight: 2 }}
            />
          </IconButton>
        </Tooltip>
      )}
      <Typography
        variant="h6"
        component="div"
        sx={{
          textAlign: "right",
          paddingRight: 3,
        }}
      >
        {accounts[0]?.name}
      </Typography>
      <Button variant="outlined" onClick={() => handleLogout()} color="inherit">
        Sign Out
      </Button>
    </>
  );
};
