import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  useCallback,
} from "react";
import {
  Box,
  FormControl,
  Input,
  Select,
  MenuItem,
  ListSubheader,
  Grid2,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import {
  DarkMode,
  CustomGroupData,
  DropToggle,
  SelectedGroupOption,
} from "../context";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

export default function CustomGroupSearch() {
  const { darkMode } = useContext(DarkMode);
  const { selectedGroupOption, setSelectedGroupOption } =
    useContext(SelectedGroupOption);
  const { setDropToggle } = useContext(DropToggle);
  const { customGroupData } = useContext(CustomGroupData);
  const [formattedGroupData, setFormattedGroupData] = useState([]);
  const [mappedLookup, setMappedLookup] = useState({});
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () =>
      formattedGroupData.filter((option) => containsText(option, searchText)),
    [searchText, formattedGroupData]
  );

  function SelectedOption(selectoption) {
    setDropToggle([]);
    setSelectedGroupOption(selectoption);
    mappedLookup[selectoption] &&
      setDropToggle(mappedLookup[selectoption]["mappedgroups"]);
  }

  const FormattedData = useCallback(() => {
    var temp_custom_group_data = [];
    var temp_lookup_data = {};
    var change_found = false;
    customGroupData?.groups.forEach((element) => {
      // both lists are not same length therefore must update it
      if (customGroupData?.groups.length !== formattedGroupData.length) {
        change_found = true;
      }
      // i cant find this group in select list therefor must update it
      if (formattedGroupData.includes(!element?.groupname) && !change_found) {
        change_found = true;
      }
      if (selectedGroupOption === element?.groupname && !change_found) {
        // check if old mappedgroup is same length as new mapped group
        if (
          mappedLookup[selectedGroupOption]["mappedgroups"].length !==
          element?.mappedgroups.length
        ) {
          change_found = true;
        }
        if (!change_found) {
          // check if any old mapped groups are not found in latest pulled mapped group
          mappedLookup[selectedGroupOption]["mappedgroups"].forEach(
            (mapped_group) => {
              if (mapped_group.includes(!element?.mappedgroups)) {
                change_found = true;
              }
            }
          );
        }
      }
      //  also check if mapped group list has changed
      temp_custom_group_data.push(element?.groupname);
      temp_lookup_data[element?.groupname] = {
        mappedgroups: element?.mappedgroups,
        roles: element?.roles,
      };
    });
    if (!customGroupData.length) {
      if (customGroupData?.groups.length !== formattedGroupData.length) {
        setSelectedGroupOption("");
        change_found = true;
      }
    }
    if (change_found) {
      var merged_array = temp_custom_group_data.concat([]);
      setMappedLookup(temp_lookup_data);
      setFormattedGroupData(merged_array);
    }
  }, [
    setFormattedGroupData,
    setSelectedGroupOption,
    customGroupData,
    formattedGroupData,
    mappedLookup,
    selectedGroupOption,
  ]);

  useEffect(() => {
    if (customGroupData) {
      FormattedData();
    }
  }, [customGroupData, FormattedData]);

  return (
    <Box
      sx={{
        mt: "10px",
        mb: "10px",
      }}
    >
      <FormControl fullWidth>
        <Select
          MenuProps={{ autoFocus: false }}
          id="search-select"
          value={selectedGroupOption}
          onChange={(e) => SelectedOption(e.target.value)}
          onClose={() => setSearchText("")}
          renderValue={(selectedOption) => {
            if (selectedOption.length === 0) {
              return <em>Select a group</em>;
            }
            return selectedOption;
          }}
          sx={{
            backgroundColor: darkMode ? "#242d3e" : "#ffffff",
            color: darkMode ? "white" : "#001864",
          }}
        >
          <ListSubheader
            sx={{
              backgroundColor: darkMode ? "#242d3e" : "#ffffff",
              color: darkMode ? "white" : "#001864",
            }}
          >
            <Grid2 container>
              <Grid2>
                <SearchTwoToneIcon
                  sx={{
                    color: darkMode ? "white " : "#001864",
                    fontSize: "2em",
                    paddingTop: "10px",
                  }}
                />
              </Grid2>
              <Grid2>
                <Input
                  disableUnderline
                  placeholder="Type to search..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key !== "Escape") {
                      e.stopPropagation();
                    }
                  }}
                  sx={{
                    color: darkMode ? "white " : "#001864",
                    width: "29em",
                    paddingTop: "0.6em",
                  }}
                />
              </Grid2>
            </Grid2>
          </ListSubheader>
          {displayedOptions.map((option, i) => (
            <MenuItem
              key={i}
              value={option}
              className={
                darkMode
                  ? "customsearchlistitemdark ifhovered"
                  : "customsearchlistitem ifhovered"
              }
            >
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
