import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Card,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid2,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import EnhancedTableHeadBuckets from "../functions/EnhancedTableHeadBuckets";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { UserPermissions, DarkMode } from "../context";
import "../css/table.css";

const tableHeaders = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Bucket Name",
  },
  {
    id: "objects",
    numeric: false,
    disablePadding: true,
    label: "Objects",
  },
  {
    id: "usage",
    numeric: false,
    disablePadding: false,
    label: "Usage",
  },
  {
    id: "quota",
    numeric: false,
    disablePadding: false,
    label: "Quota",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function BucketTable({ bucketTableData }) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [keyVisible, setKeyVisible] = useState("");
  const [openKeys, setOpenKeys] = useState(false);
  const [keyData, setKeyData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { userPermissions } = useContext(UserPermissions);
  const { darkMode } = useContext(DarkMode);

  useEffect(() => {
    if (bucketTableData.length < page * rowsPerPage) {
      setPage(0);
    }
  }, [bucketTableData, page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - bucketTableData.length)
      : 0;

  function GraphValue(quota, used) {
    let usedValue = Math.round((used / quota) * 100);
    if (usedValue) {
      return usedValue;
    }
    return 0;
  }

  function handleKeysClose() {
    setKeyVisible("");
    setOpenKeys(false);
  }

  function OpenKeyDialog(keyData) {
    setKeyData(keyData);
    setOpenKeys(true);
  }

  function BuildKeys() {
    const keysComponent = Object.entries(keyData?.keys).map(
      ([key, value], index) => (
        <React.Fragment key={key}>
          <Grid2 size={{ xs: 12, sm: 2, md: 2 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ color: darkMode ? "#ffffff" : "#001864" }}
            >
              {key.toUpperCase()}:
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 2, md: 2 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ color: darkMode ? "#ffffff" : "#001864" }}
            >
              Access Key
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 7, md: 7 }}>
            <Typography
              variant="h6"
              component="div"
              className={darkMode ? "keyborderdark" : "keyborder"}
            >
              {keyVisible === index + value?.access_key
                ? value?.access_key
                : "***************************************************"}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 1, md: 1 }}>
            <Tooltip
              title={
                keyVisible === index + value?.access_key
                  ? "Hide Access Key"
                  : "Show Access Key"
              }
              placement="left"
            >
              <IconButton
                aria-label="show_access_key"
                onClick={() => {
                  setKeyVisible(
                    keyVisible === index + value?.access_key
                      ? ""
                      : index + value?.access_key
                  );
                }}
              >
                {keyVisible === index + value?.access_key ? (
                  <VisibilityTwoToneIcon sx={{ color: "#FFC20E" }} />
                ) : (
                  <VisibilityOffTwoToneIcon sx={{ color: "#FFC20E" }} />
                )}
              </IconButton>
            </Tooltip>

            <Tooltip title={"Copy to clipboard"} placement="right">
              <IconButton
                aria-label="access_key"
                onClick={() => {
                  navigator.clipboard.writeText(value?.access_key);
                }}
              >
                <ContentCopyTwoToneIcon sx={{ color: "#FFC20E" }} />
              </IconButton>
            </Tooltip>
          </Grid2>

          <Grid2 size={{ xs: 12, sm: 2, md: 2 }}></Grid2>
          <Grid2 size={{ xs: 12, sm: 2, md: 2 }}>
            <Typography
              variant="h6"
              component="div"
              sx={{ color: darkMode ? "#ffffff" : "#001864" }}
            >
              Secret
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 7, md: 7 }}>
            <Typography
              variant="h6"
              component="div"
              className={darkMode ? "keyborderdark" : "keyborder"}
            >
              {keyVisible === index + value?.secret
                ? value?.secret
                : "***************************************************"}
            </Typography>
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 1, md: 1 }}>
            <Tooltip
              title={
                keyVisible === index + value?.secret
                  ? "Hide Secret Key"
                  : "Show Secret Key"
              }
              placement="left"
            >
              <IconButton
                aria-label="show_secret_key"
                onClick={() => {
                  setKeyVisible(
                    keyVisible === index + value?.secret
                      ? ""
                      : index + value?.secret
                  );
                }}
              >
                {keyVisible === index + value?.secret ? (
                  <VisibilityTwoToneIcon sx={{ color: "#FFC20E" }} />
                ) : (
                  <VisibilityOffTwoToneIcon sx={{ color: "#FFC20E" }} />
                )}
              </IconButton>
            </Tooltip>
            <Tooltip title={"Copy to clipboard"} placement="right">
              <IconButton
                aria-label="secret"
                onClick={() => {
                  navigator.clipboard.writeText(value?.secret);
                }}
              >
                <ContentCopyTwoToneIcon sx={{ color: "#FFC20E" }} />
              </IconButton>
            </Tooltip>
          </Grid2>
        </React.Fragment>
      )
    );
    return keysComponent;
  }

  function CircularProgressWithLabel(props) {
    let s3color = "blue.500";
    if (props.value < 30) {
      s3color = "success";
    } else if (props.value < 80) {
      s3color = "info";
    } else {
      s3color = "error";
    }

    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} color={s3color} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" sx={{ color: s3color }}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box className="boxtable">
      <TableContainer>
        <Table
          className="generalTable"
          sx={{ minWidth: "100%" }}
          aria-labelledby="tableTitle"
          size={"small"}
        >
          <EnhancedTableHeadBuckets
            sx={{
              mb: 0,
            }}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={bucketTableData.length}
            headCells={tableHeaders}
          />
          <TableBody>
            {bucketTableData
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const nameId = `enhanced-table-name-${index}`;
                const objectsId = `enhanced-table-objects-${index}`;
                const usageId = `enhanced-table-usage-${index}`;
                const quotaId = `enhanced-table-quota-${index}`;
                const actionsId = `enhanced-table-actions-${index}`;
                return (
                  <TableRow hover tabIndex={-1} key={row.name + index}>
                    <TableCell
                      className={darkMode ? "tablecelldark" : "tablecell"}
                      component="th"
                      id={nameId}
                      scope="row"
                      padding="normal"
                    >
                      {row?.name}
                    </TableCell>

                    <TableCell
                      className={darkMode ? "tablecelldark" : "tablecell"}
                      component="th"
                      id={objectsId}
                      scope="row"
                      padding="none"
                    >
                      {row?.objects}
                    </TableCell>

                    <TableCell
                      className={darkMode ? "tablecelldark" : "tablecell"}
                      component="th"
                      scope="row"
                      padding="none"
                      id={usageId}
                    >
                      <Grid2
                        container
                        columnSpacing={2}
                        sx={{
                          paddingTop: "0.5vh",
                          paddingBottom: "0.5vh",
                          paddingLeft: "1vw",
                        }}
                      >
                        <Typography sx={{ paddingTop: "1vh" }}>
                          {row?.usage}
                        </Typography>
                        {row?.graph?.size ? (
                          <CircularProgressWithLabel
                            value={GraphValue(
                              row?.graph?.quota,
                              row?.graph?.size
                            )}
                          />
                        ) : null}
                      </Grid2>
                    </TableCell>

                    <TableCell
                      className={darkMode ? "tablecelldark" : "tablecell"}
                      component="th"
                      scope="row"
                      padding="none"
                      id={quotaId}
                    >
                      <Typography sx={{ paddingLeft: "1vw" }}>
                        {row?.quota}
                      </Typography>
                    </TableCell>
                    {userPermissions?.keyaccess && (
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        id={actionsId}
                      >
                        <Tooltip
                          title={"Click to view bucket keys"}
                          placement="left"
                        >
                          <IconButton onClick={() => OpenKeyDialog(row)}>
                            <VisibilityTwoToneIcon
                              className={darkMode ? "viewkeysdark" : "viewkeys"}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {rowsPerPage < 6 && (
        <TablePagination
          className={darkMode ? "paginationdark" : "pagination"}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={bucketTableData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      <Dialog onClose={handleKeysClose} open={openKeys} maxWidth="lg">
        <Card className={darkMode ? "appcardsdark" : "appcards"}>
          <DialogTitle>
            <Typography
              variant="h4"
              component="div"
              sx={{ color: darkMode ? "#ffffff" : "#001864" }}
            >
              KEYS FOR: {keyData?.name.toUpperCase()}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid2 container rowSpacing={2}>
              <Grid2 size={{ xs: 2, sm: 2, md: 2, xl: 2 }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: darkMode ? "#ffffff" : "#001864" }}
                >
                  URL:
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 7, sm: 7, md: 9, xl: 9 }}>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ color: darkMode ? "#ffffff" : "#001864" }}
                >
                  https://{keyData?.url}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, md: 1, xl: 1 }}>
                <Tooltip
                  title={"Copy URL to clipboard"}
                  placement="left"
                  sx={{ marginLeft: "40px" }}
                >
                  <IconButton
                    aria-label="url"
                    onClick={() => {
                      navigator.clipboard.writeText(`https://${keyData?.url}`);
                    }}
                  >
                    <ContentCopyTwoToneIcon sx={{ color: "#FFC20E" }} />
                  </IconButton>
                </Tooltip>
              </Grid2>
              {keyData?.keys && BuildKeys()}
            </Grid2>
          </DialogContent>
        </Card>
      </Dialog>
    </Box>
  );
}
