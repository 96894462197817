import React, { useContext } from "react";
import { DragPreviewImage, useDrag } from "react-dnd";
import { ItemTypes } from "../ItemTypes";
import { ListItem, ListItemText, IconButton } from "@mui/material";
import { ItemMoved, DarkMode, DropToggle } from "../context";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import "../css/configpage.css";

export const EntraGroupItem = ({ data, index, customgroup }) => {
  const { darkMode } = useContext(DarkMode);
  const { setDropToggle, dropToggle } = useContext(DropToggle);
  const { setItemMoved } = useContext(ItemMoved);
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: ItemTypes.LIST,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    []
  );

  function RemoveGroup(groupinfo) {
    const index = dropToggle.indexOf(groupinfo);
    var array2 = [];
    const array3 = array2.concat(dropToggle);
    if (index > -1) {
      array3.splice(index, 1);
      setDropToggle(array3);
    } else {
      setDropToggle([]);
    }
  }

  function UpdateMoveData() {
    setItemMoved(data);
  }

  return (
    <React.Fragment key={`${index} + fragment`}>
      <DragPreviewImage key={`${index} + preview`} connect={preview} src={""} />
      <ListItem
        onDragStart={customgroup ? null : UpdateMoveData}
        ref={customgroup ? null : drag}
        key={`${index} + listitem`}
        className={darkMode ? "entralistitemdark" : "entralistitem"}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: customgroup ? "default" : "move",
        }}
      >
        {customgroup && (
          <IconButton onClick={() => RemoveGroup(data)}>
            <HighlightOffTwoToneIcon color="error" />
          </IconButton>
        )}
        <ListItemText key={`${index} + listtext`} primary={data} />
      </ListItem>
    </React.Fragment>
  );
};
