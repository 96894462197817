import React from "react";
import { SignInButton } from "../components/SignInButton";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

export default function Login({ darkMode }) {
  return (
    <Box sx={{ height: "98vh", padding: "25vh 0" }}>
      <Grid container rowSpacing={5}>
        <Grid size={12}>
          <Box sx={{ height: "16vh", display: "flex" }}>
            <img
              src={
                darkMode
                  ? "./objectslogo_login_white.png"
                  : "./objectslogo_login.png"
              }
              alt="logo"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </Box>
        </Grid>
        <Grid size={12} sx={{ textAlign: "center" }}>
          <SignInButton />
        </Grid>
      </Grid>
    </Box>
  );
}
