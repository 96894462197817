import React from "react";
import { EntraGroupItem } from "./EntraGroupItem";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";

function BuildList(listdata) {
  if (listdata.length) {
    const list_data = listdata.map((x, index) => (
      <EntraGroupItem
        key={`${index} entragroupitem`}
        data={x}
        index={index}
        customgroup={true}
      />
    ));
    return list_data;
  } else {
    return <div></div>;
  }
}
export default function CustomGroupList({ dropToggle }) {
  return (
    <Box>
      <List
        key={"fulllist"}
        dense={true}
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "transparent",
          position: "relative",
          overflow: "auto",
          maxHeight: "48.5vh",
          "& ul": { padding: 0 },
        }}
      >
        <Stack>{BuildList(dropToggle)}</Stack>
      </List>
    </Box>
  );
}
