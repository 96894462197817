import React, { useState, useContext, useEffect } from "react";
import { Snackbar, Alert } from "@mui/material";
import { AlertResponses, NewAlert } from "../context";

export default function SnackAlert() {
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("success");
  const { alertResponses, setAlertResponses } = useContext(AlertResponses);
  const { newAlert, setNewAlert } = useContext(NewAlert);

  useEffect(() => {
    if (newAlert?.message) {
      var shift_array = [].concat(alertResponses);
      shift_array.unshift({
        color: newAlert.color,
        message: newAlert.message,
      });
      if (newAlert.color !== "success") {
        setAlertResponses(shift_array);
      }
      setMessage(newAlert.message);
      setColor(newAlert.color);
      setOpenSnack(true);
      setNewAlert({});
    }
  }, [newAlert, setAlertResponses, alertResponses, setNewAlert]);

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <div>
      <Snackbar
        open={openSnack}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={color}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
