import React, { useContext } from "react";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { DarkMode, UserPermissions } from "../context";
import "../css/table.css";

export default function EnhancedTableHeadBuckets(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const { darkMode } = useContext(DarkMode);
  const { userPermissions } = useContext(UserPermissions);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  function BuildHeadCell(headCell) {
    return (
      <TableCell
        key={headCell.id}
        align={headCell.numeric ? "center" : "left"}
        padding={headCell.disablePadding ? "none" : "normal"}
        sortDirection={orderBy === headCell.id ? order : false}
      >
        <TableSortLabel
          className={darkMode ? "tablesortlabeldark" : "tablesortlabel"}
          active={orderBy === headCell.id}
          direction={orderBy === headCell.id ? order : "asc"}
          onClick={createSortHandler(headCell.id)}
        >
          {headCell.label}
          {orderBy === headCell.id ? (
            <Box component="span" sx={visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </Box>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>
          !userPermissions?.keyaccess && headCell.label !== "Actions"
            ? BuildHeadCell(headCell)
            : !userPermissions?.keyaccess && headCell.label === "Actions"
            ? null
            : BuildHeadCell(headCell)
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadBuckets.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
