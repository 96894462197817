import React, { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { PageLocation } from "../context";
import "../css/notfound.css";

export default function NotFound() {
  const { setPageLocation } = useContext(PageLocation);

  return (
    <Box className="notfoundBox">
      <Typography
        variant="h3"
        component="div"
        color="primary"
        sx={{
          textAlign: "center",
        }}
      >
        Page was not found
      </Typography>
      <Button
        variant="outlined"
        onClick={() => setPageLocation("dashboard")}
        color="primary"
        sx={{ marginTop: "5vh" }}
      >
        Home
      </Button>
    </Box>
  );
}
