import React, { useEffect, useContext, useState, useCallback } from "react";
import { CustomGroupItem } from "./CustomGroupsItem";
import CustomGroupSearch from "./CustomGroupSearch";
import AddGroupModal from "./AddGroupModal";
import {
  Box,
  CardContent,
  Grid2,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  Button,
  Card,
  CardHeader,
  CardActions,
  List,
  ListItem,
  CircularProgress,
} from "@mui/material";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import ClearTwoToneIcon from "@mui/icons-material/ClearTwoTone";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import FallStreakAPI from "../api/FallStreakApi";
import {
  DarkMode,
  DropToggle,
  SelectedGroupOption,
  TokenData,
  AllRolesPermissions,
  CustomGroupData,
  NewAlert,
} from "../context";

export default function CustomGroups({
  dropitemtracking,
  setRefresh,
  refresh,
}) {
  const { tokenData } = useContext(TokenData);
  const { setNewAlert } = useContext(NewAlert);
  const { allRolesPermissions } = useContext(AllRolesPermissions);
  const [selectRoles, setSelectRoles] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { customGroupData } = useContext(CustomGroupData);
  const [selectedRoleOption, setSelectedRoleOption] = useState("");
  const [groupName, setGroupName] = useState("");
  const [permissionDetailsLookup, setPermissionDetailsLookup] = useState({});
  const [roleDetailsLookup, setRoleDetailsLookup] = useState({});
  const { darkMode } = useContext(DarkMode);
  const [summaryList, setSummaryList] = useState([]);
  const { dropToggle, setDropToggle } = useContext(DropToggle);
  const { selectedGroupOption, setSelectedGroupOption } =
    useContext(SelectedGroupOption);

  const MatchGroupRole = useCallback(
    (groupname) => {
      var role_array = false;
      customGroupData["groups"].forEach((element) => {
        if (groupname === element["groupname"]) {
          role_array = element?.["roles"];
        }
      });
      return role_array;
    },
    [customGroupData]
  );

  function clearSelectedGroupOption() {
    setDropToggle([]);
    setSelectedGroupOption("");
  }

  function handleClose() {
    setDeleteOpen(false);
    setAddOpen(false);
    setSelectedRoleOption("");
    setGroupName("");
  }

  function handleAddGroupOpen() {
    setSelectedRoleOption("guest");
    setGroupName("");
    setAddOpen(true);
  }

  useEffect(() => {
    var temp_roles = [];
    var temp_permissions_lookup = {};
    var temp_role_lookup = {};
    var map_roles = allRolesPermissions?.roles;
    var map_permissions = allRolesPermissions?.permissions;
    if (map_roles) {
      map_roles.forEach((element) => {
        temp_roles.push(element?.role);
        temp_role_lookup[element?.role] = element?.permissions;
      });
      if (map_permissions) {
        map_permissions.forEach((element) => {
          temp_permissions_lookup[element["id"]] = element;
        });
      }
      setRoleDetailsLookup(temp_role_lookup);
      setPermissionDetailsLookup(temp_permissions_lookup);
      setSelectRoles(temp_roles);
    }
  }, [allRolesPermissions, setSelectRoles, setPermissionDetailsLookup]);

  useEffect(() => {
    if (roleDetailsLookup && permissionDetailsLookup) {
      if (selectedGroupOption || selectedRoleOption) {
        var temp_summary_list = [];
        var matched_option = selectedRoleOption
          ? selectedRoleOption
          : MatchGroupRole(selectedGroupOption);
        if (matched_option) {
          if (matched_option?.[0] === "guest" || matched_option === "guest") {
            temp_summary_list.push(
              "A guest user can simply log in, and will not see any information"
            );
          } else {
            var permissions_array = roleDetailsLookup?.[matched_option];
            if (permissions_array) {
              permissions_array.forEach((element) => {
                temp_summary_list.push(
                  permissionDetailsLookup?.[element]["description"]
                );
              });
            }
          }
        } else {
          temp_summary_list.push(
            "A guest user can simply log in, and will not see any information"
          );
        }
      }
      setSummaryList(temp_summary_list);
    }
  }, [
    selectedRoleOption,
    roleDetailsLookup,
    permissionDetailsLookup,
    selectedGroupOption,
    MatchGroupRole,
  ]);

  function ConfirmDelete() {
    setDeleteOpen(true);
  }

  function crudGroup(requesttype) {
    var matched_option = "";
    if (requesttype === "edit") {
      matched_option = MatchGroupRole(selectedGroupOption);
      if (!matched_option) {
        setNewAlert({
          uid: "customgroups",
          color: "error",
          message:
            "Something went wrong updating the group. Please contact support",
        });
        return;
      }
    }

    FallStreakAPI(
      {
        token_data: { token_data: tokenData },
        group_details: {
          groupname:
            requesttype === "edit"
              ? selectedGroupOption
              : requesttype === "delete"
              ? selectedGroupOption
              : groupName,
          mappedgroups: requesttype === "edit" ? dropToggle : [],
          roles:
            requesttype === "edit"
              ? matched_option
              : selectedRoleOption
              ? [selectedRoleOption]
              : ["guest"],
        },
        delete_flag: { delete_flag: requesttype === "delete" ? true : false },
        add_flag: { add_flag: requesttype === "create" ? true : false },
      },
      requesttype === "delete" ? "DELETE" : "PUT",
      "crudgroup",
      ""
    )
      .then(function (response) {
        if (response?.status === 200) {
          if (response?.data) {
            if (requesttype === "create") {
              setDropToggle([]);
            }
            setNewAlert({
              color: "success",
              message:
                requesttype === "edit"
                  ? `Group updated: ${response?.data?.groupname}`
                  : requesttype === "delete"
                  ? `Group deleted: ${response?.data?.groupname}`
                  : `Group created: ${response?.data?.groupname}`,
            });
            handleClose();
          }
          setRefresh(true);
        } else if (response?.status === 401) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        } else if (response?.status === 403) {
          setNewAlert({
            color: "error",
            message: response?.response?.data?.detail,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
        setNewAlert({ color: "error", message: err });
      });
  }

  function handleRoleSelect(value) {
    setSelectedRoleOption(value);
  }

  function groupSummaryToolTip() {
    return summaryList ? (
      <List>
        {summaryList.map((option, i) => (
          <ListItem
            key={i}
            className={
              darkMode ? "customsearchlistitemdark" : "customsearchlistitem"
            }
          >
            {`- ${option}`}
          </ListItem>
        ))}
      </List>
    ) : null;
  }

  useEffect(() => dropitemtracking.observe());

  return (
    <CardContent>
      <Grid2 container>
        <Grid2 size={4.6} sx={{ paddingBottom: "5px" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              textAlign: "left",
            }}
          >
            {"Custom Groups / Roles"}
          </Typography>
        </Grid2>
        <Grid2 size={2} sx={{ marginTop: "-5px" }}>
          <Tooltip title="Groups that can be mapped to MS groups and Roles to enable user access and permissions">
            <HelpTwoToneIcon
              className={darkMode ? "helpiconconfigdark" : "helpiconconfig"}
              sx={{ pt: "9px" }}
            />
          </Tooltip>
        </Grid2>
        <Grid2 size={2} sx={{ marginTop: "-5px" }}>
          <Tooltip title={"Add a custom group"}>
            <Button
              variant="outlined"
              onClick={() => handleAddGroupOpen()}
              className={darkMode ? "addiconconfigdark" : "addiconconfig"}
            >
              Add
            </Button>
          </Tooltip>
        </Grid2>

        <Grid2 size={8}>
          <CustomGroupSearch />
        </Grid2>
        {selectedGroupOption !== "" ? (
          <Grid2 size={1} sx={{ paddingTop: "11px" }}>
            <Tooltip title={groupSummaryToolTip()}>
              <InfoTwoToneIcon
                className={darkMode ? "infoiconconfigdark" : "infoiconconfig"}
              />
            </Tooltip>
          </Grid2>
        ) : null}
        {selectedGroupOption !== "" ? (
          <Grid2 size={1} sx={{ paddingTop: "11px" }}>
            <Tooltip title="Delete the Group">
              <IconButton sx={{ mb: "0px" }} onClick={() => ConfirmDelete()}>
                <DeleteForeverTwoToneIcon
                  className={
                    darkMode ? "deleteiconconfigdark" : "deleteiconconfig"
                  }
                />
              </IconButton>
            </Tooltip>
          </Grid2>
        ) : null}
        <Grid2 size={1} sx={{ paddingTop: "11px" }}>
          {refresh ? (
            <Box sx={{ marginLeft: "10px", marginTop: "5px" }}>
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <Tooltip title="Refresh Custom Groups">
              <IconButton sx={{ mb: "0px" }} onClick={() => setRefresh(true)}>
                <RefreshTwoToneIcon
                  className={
                    darkMode ? "refreshiconconfigdark" : "refreshiconconfig"
                  }
                />
              </IconButton>
            </Tooltip>
          )}
        </Grid2>
        <Grid2 size={1} sx={{ paddingTop: "11px" }}>
          {selectedGroupOption !== "" ? (
            <Tooltip title="Clear Selected Option">
              <IconButton
                sx={{ mb: "0px" }}
                onClick={() => clearSelectedGroupOption()}
              >
                <ClearTwoToneIcon
                  className={
                    darkMode ? "cleariconconfigdark" : "cleariconconfig"
                  }
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </Grid2>
        {selectedGroupOption !== "" ? (
          <>
            <Grid2 size={10}>
              <CustomGroupItem dropitemtracking={dropitemtracking} />
            </Grid2>
            <Grid2 size={2}></Grid2>
          </>
        ) : null}
        {selectedGroupOption !== "" ? (
          <>
            <Grid2 size={4.6}></Grid2>
            <Grid2 size={2}>
              <Tooltip title="Save Changes">
                <IconButton onClick={() => crudGroup("edit")}>
                  <SaveTwoToneIcon
                    className={
                      darkMode ? "saveiconconfigdark" : "saveiconconfig"
                    }
                  />
                </IconButton>
              </Tooltip>
            </Grid2>
          </>
        ) : null}
      </Grid2>
      <Dialog open={deleteOpen} onClose={handleClose}>
        <Card className={darkMode ? "appcardsdark" : "appcards"}>
          <CardHeader
            title="Are you sure?"
            className={darkMode ? "appcardstitledark" : "appcardstitle"}
          />
          <CardActions>
            <Button
              variant="outlined"
              onClick={() => handleClose()}
              color="inherit"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              onClick={() => crudGroup("delete")}
              color="inherit"
            >
              Delete
            </Button>
          </CardActions>
        </Card>
      </Dialog>
      <AddGroupModal
        addOpen={addOpen}
        handleClose={handleClose}
        setGroupName={setGroupName}
        groupName={groupName}
        selectedRoleOption={selectedRoleOption}
        selectRoles={selectRoles}
        handleRoleSelect={handleRoleSelect}
        crudGroup={crudGroup}
        summaryList={summaryList}
      />
    </CardContent>
  );
}
