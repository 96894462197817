import React, { useContext, useEffect, useCallback } from "react";
import { Box } from "@mui/material";
import FallStreakAPI from "../api/FallStreakApi";
import { DarkMode, TokenData } from "../context";

export default function Onboarding() {
  const { darkMode } = useContext(DarkMode);
  const { tokenData } = useContext(TokenData);
  // tenantgroupsinfo

  return <Box>Onboarding</Box>;
}
