import React, { useContext } from "react";
import { Input, Card, Grid2 } from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { DarkMode } from "../context";
import "../css/app.css";
/**
 * Option to search for bucket names save term to context
 * @param bucketData
 */
export default function CustomSearch({
  setSearchTerm,
  searchTerm,
  placeholder,
}) {
  const { darkMode } = useContext(DarkMode);

  return (
    <Card className={darkMode ? "appcardsdark" : "appcards"}>
      <Grid2 container>
        <Grid2 size={{ xs: 2, sm: 2, md: 1 }}>
          <SearchTwoToneIcon
            sx={{
              color: darkMode ? "white " : "black",
              fontSize: "2em",
              paddingTop: "10px",
            }}
          />
        </Grid2>
        <Grid2 size={{ xs: 10, sm: 10, md: 11 }}>
          <Input
            disableUnderline
            placeholder={placeholder}
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
            sx={{
              color: darkMode ? "white " : "black",
              width: "29em",
              paddingTop: "0.6em",
            }}
          />
        </Grid2>
      </Grid2>
    </Card>
  );
}
