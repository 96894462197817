import React, { useContext, useState } from "react";
import {
  Box,
  Grid2,
  IconButton,
  Tooltip,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
} from "@mui/material";
import "../css/footer.css";
import WarningTwoToneIcon from "@mui/icons-material/WarningTwoTone";
import { DarkMode, AlertResponses } from "../context";

export default function Footer() {
  const { darkMode } = useContext(DarkMode);
  const { alertResponses } = useContext(AlertResponses);
  const [openAlerts, setOpenAlerts] = useState(false);

  function handleClose() {
    setOpenAlerts(false);
  }

  return (
    <Box className={darkMode ? "footerdark" : "footer"}>
      <Grid2 container>
        <Grid2 size={4}></Grid2>
        <Grid2 size={4} sx={{ mt: "15px" }}>
          {"Arcus S3 Objects"} &copy; {new Date().getFullYear()}
        </Grid2>
        <Grid2 size={3}></Grid2>
        <Grid2 size={1}>
          <Tooltip
            title={
              alertResponses?.length
                ? `There are currently ${alertResponses?.length} alerts`
                : "There are no current alerts"
            }
          >
            <IconButton
              sx={{ cursor: !alertResponses?.length && "default" }}
              onClick={() =>
                alertResponses?.length ? setOpenAlerts(true) : null
              }
            >
              <WarningTwoToneIcon
                className={
                  darkMode
                    ? alertResponses?.length
                      ? "alerticonactivedark"
                      : "alerticondark"
                    : alertResponses?.length
                    ? "alerticonactive"
                    : "alerticon"
                }
              />
            </IconButton>
          </Tooltip>
        </Grid2>
      </Grid2>
      <Dialog open={openAlerts} onClose={handleClose}>
        <Card className={darkMode ? "appcardsdark" : "appcards"}>
          <CardHeader
            title="Alert History - Last 5 alerts"
            className={darkMode ? "appcardstitledark" : "appcardstitle"}
          />
          <CardContent>
            <List>
              {alertResponses.slice(0, 5).map((option, index) => {
                return (
                  <ListItem
                    key={index}
                    className={
                      darkMode
                        ? "customsearchlistitemdark"
                        : "customsearchlistitem"
                    }
                  >
                    {"- " + option.message}
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Dialog>
    </Box>
  );
}
