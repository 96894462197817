import { createContext } from "react";

export const ClientName = createContext({
  clientNameString: "",
  setClientNameString: () => "",
});

export const BucketTableData = createContext({
  bucketTableData: null,
  setBucketTableData: () => null,
});

export const BucketSearch = createContext({
  bucketSearchTerm: "",
  setBucketSearchTerm: () => "",
});

export const UserPermissions = createContext({
  userPermissions: {},
  setUserPermissions: () => {},
});

export const PageLocation = createContext({
  pageLocation: "dashboard",
  setPageLocation: () => "",
});

export const DarkMode = createContext({
  darkMode: false,
  setDarkMode: () => "",
});

export const TokenData = createContext({
  tokenData: null,
  setTokenData: () => null,
});

export const DropToggle = createContext({
  dropToggle: [],
  setDropToggle: () => [],
});

export const ItemMoved = createContext({
  itemMoved: [],
  setItemMoved: () => [],
});

export const CustomGroupData = createContext({
  customGroupData: null,
  setCustomGroupData: () => null,
});

export const TenantGroupData = createContext({
  tenantGroupData: null,
  setTenantGroupData: () => null,
});

export const SelectedGroupOption = createContext({
  selectedGroupOption: null,
  setSelectedGroupOption: () => null,
});

export const AlertResponses = createContext({
  alertResponses: [],
  setAlertResponses: () => [],
});

export const NewAlert = createContext({
  newAlert: {},
  setNewAlert: () => {},
});

export const AllRolesPermissions = createContext({
  allRolesPermissions: [],
  setAllRolesPermissions: () => [],
});
